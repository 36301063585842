<template>
  <div v-if="homeData">
    <PagesPath :pathTitle1="'تماس با ما'" :pathTo1="'contact'"></PagesPath>
    <section
      id="contactContainer"
      class="
          contact
          container
          my-sm-5 my-4
          box-shaddow20
          bg-white

          p-3
        "
    >
      <h5 class="font-weight-bold text-color-444">
        تماس با {{ homeData.settings.customer_setting?homeData.settings.customer_setting.title:"" }}
      </h5>
      <p>
        برای برقراری ارتباط با ما میتوانید از طریق آدرس
        <b>
          {{ homeData.settings.customer_setting?homeData.settings.customer_setting.address:"" }}
        </b>
        و یا شماره پشتیبانی
        <b>
          {{ homeData.settings.customer_setting?homeData.settings.customer_setting.mobile:"" }}
        </b>
        اقدام فرمائید
      </p>
    </section>
    <Footer></Footer>
  </div>
</template>

<script>
import PagesPath from "@/parts/Front/components/PagesPath";
import ZTwoArrowSvg from "../components/shared/ZTwoArrowSvg";
import SocialNetworks from "../components/shared/SocialNetworks";
export default {
  name: "Contact",
  inject: ["mediaQueries"],
  components: {
    SocialNetworks,
    ZTwoArrowSvg,
    PagesPath,
  },
  data() {
    return {
      showFaq: false,
      code: null,
      question: null,
      disabled: false,
    };
  },
  computed: {
    // دریافت محتوای درخواست هوم
    homeData() {
      return this.$store.getters["front/getHomeData"];
    },
    loading() {
      return this.$store.getters["front/getHomeDataStatus"];
    },
    faqs() {
      return this.$store.getters["front/getFaqList"];
    },
  },
  mounted() {
    if (!this.faqs) {
      this.$store.dispatch("front/getAllFaqListFromServer");
    }
    this.$axios.get("all/contacts/create").then((response) => {
      this.code = response.data.data.name;
      this.question = response.data.data.message;
    });
  },
  methods: {
    sendForm() {
      if (this.$refs.name.value.length < 2) {
        this.$refs.name.classList.add("inputError");
        return window
          .swal({
            title: "وارد کردن  نام الزامی است",
            text: "نام باید شامل دو کارکتر یا بیشتر باشد",
            icon: "warning",

            showConfirmButton: true,
            dangerMode: true,
            button: "متوجه شدم",
          })
          .then(() => {
            this.$refs.name.focus();
          });
      } else {
        this.$refs.name.classList.remove("inputError");
      }
      if (this.$refs.phone.value.length != 11) {
        this.$refs.phone.classList.add("inputError");
        return window
          .swal({
            title: "ورود کردن شماره تماس الزامی است",
            text: "شماره تماس شامل 11 رقم است",
            icon: "warning",

            showConfirmButton: true,
            dangerMode: true,
            button: "متوجه شدم",
          })
          .then(() => {
            this.$refs.phone.focus();
          });
      } else {
        this.$refs.phone.classList.remove("inputError");
      }
      if (this.$refs.subject.value.length < 3) {
        this.$refs.subject.classList.add("inputError");
        return window
          .swal({
            title: "موضوع ارتباط را به درستی وارد کنید",
            icon: "warning",

            showConfirmButton: true,
            dangerMode: true,
            button: "متوجه شدم",
          })
          .then(() => {
            this.$refs.subject.focus();
          });
      } else {
        this.$refs.subject.classList.remove("inputError");
      }
      if (this.$refs.body.value.length < 3) {
        this.$refs.body.classList.add("inputError");
        return window
          .swal({
            title: "متن پیام خود را وارد کنید",
            text: "این متن نباید از 10 کارکتر کمتر باشد",
            icon: "warning",

            showConfirmButton: true,
            dangerMode: true,
            button: "متوجه شدم",
          })
          .then(() => {
            this.$refs.body.focus();
          });
      } else {
        this.$refs.body.classList.remove("inputError");
      }
      if (this.$refs.answer.value.length < 1) {
        this.$refs.answer.classList.add("inputError");
        return window
          .swal({
            title: "پاسخ سوال امنیتی را وارد کنید",
            icon: "warning",

            showConfirmButton: true,
            dangerMode: true,
            button: "متوجه شدم",
          })
          .then(() => {
            this.$refs.answer.focus();
          });
      } else {
        this.$refs.answer.classList.remove("inputError");
      }
      const formData = new FormData();
      formData.append("name", this.$refs.name.value);
      formData.append("phone_number", this.$refs.phone.value);
      formData.append("subject", this.$refs.subject.value);
      formData.append("body", this.$refs.body.value);
      formData.append("" + this.code, this.$refs.answer.value);

      this.disabled = true;
      this.$axios
        .post("/all/contacts", formData)
        .then((res) => {
          this.disabled = false;
          this.$refs.name.value = null;
          this.$refs.phone.value = null;
          this.$refs.subject.value = null;
          this.$refs.body.value = null;
          this.$refs.answer.value = null;
          window.swal({
            title: "ارتباط با ما برقرار شد",
            text:
              "پیام شما در سیستم ما درج و به زودی پاسخ آنرا دریافت خواهید کرد باتشکر!",
            icon: "success",

            showConfirmButton: true,
            button: "متوجه شدم",
          });
        })
        .catch((err) => {
          this.disabled = false;
          window
            .swal({
              title: "پاسخ سوال امنیتی را  به درستی وارد نکرده اید",
              icon: "warning",

              showConfirmButton: true,
              dangerMode: true,
              button: "متوجه شدم",
            })
            .then(() => {
              this.$refs.answer.focus();
            });
        });
    },
  },
};
</script>

<style scoped>
/* برداشتن دکمه های بالا پایین برای ورود عدد */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
#answerBox {
  padding: 5px !important;
  width: 70px;
  border-radius: 4px;
}
#contactCheckAndSubmit {
  display: flex;
  flex-wrap: wrap;
}
.contact-text {
  margin-bottom: 10px;
}
.disabled {
  pointer-events: none;
  opacity: 0.5;
}
.inputError {
  border: 1px solid red !important;
  background: #efe1e4;
}
button#toggleFaq {
  padding: 10px 15px;
  color: white;
  font-size: 15px;
  font-weight: bold;
  background: #43c7fc;
}
div#faqSection {
  margin: 10px 0;
}
div#faqSection p {
  padding: 10px;
  margin: 0 0 10px;
  box-shadow: 0 0 10px #00000045;
  font-size: 16px;
  font-family: "yekan-bakh";
  background: #b8b8b8;
  color: black;
}
div#faqSection summary {
  padding: 10px;
  background: white;
  box-shadow: 0 0 5px #00000030;
  font-weight: bold;
}
section#footerContent h4 {
  margin: 19px 0px;
}
section#footerContent {
  margin: 15px 0;
}
@media (max-width: 768px) {
  .faqToggle {
    flex-direction: column;
  }
}
#contactContainer {
  min-height: 70vh;
}
</style>
