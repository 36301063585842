<template>
  <section class="payment-method bg-white box-shaddow20 container mb-5">
    <div class="d-flex  text-right p-3 border-bottom">
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M21.6389 14.3958H17.5906C16.1042 14.3949 14.8994 13.1909 14.8985 11.7045C14.8985 10.218 16.1042 9.01413 17.5906 9.01321H21.6389"
          stroke="#666"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M18.0486 11.6429H17.7369"
          stroke="#666"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M7.74766 3H16.3911C19.2892 3 21.6388 5.34951 21.6388 8.24766V15.4247C21.6388 18.3229 19.2892 20.6724 16.3911 20.6724H7.74766C4.84951 20.6724 2.5 18.3229 2.5 15.4247V8.24766C2.5 5.34951 4.84951 3 7.74766 3Z"
          stroke="#666"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M7.03561 7.53817H12.4346"
          stroke="#666"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
      <h6 class="fontsize-large text-color-444 mt-1 mr-1 weight-bold">
        انتخاب شیوه پرداخت
      </h6>
    </div>
    <div class="row p-3">
      <section class="internet-payment col-md-6 col-12 p-3 bg-color-fa border">
        <div
          @click="(e) => selectPayMethod(e, false)"
          class="cursor-pointer d-flex align-items-center mb-1"
        >
          <div class="align-self-center order-type ml-2 d-inline-block">
            <label class="address-checked-container">
              <input
                ref="radioGateway"
                type="radio"
                :checked="true"
                name="pay_method"
              />
              <span class="address-checkmark"></span>
            </label>
          </div>
          <img
            :src="require('@/assets/images/money.png')"
            class="wallet-img mr-3"
            :alt="'wallet'"
          />
          <h6 class="mr-1 mb-0 text-color-444 fontsize-medium">
            پرداخت اینترنتی
          </h6>
        </div>
        <p class="text-color-666 fontsize-small">
          پرداخت با کلیه کارت های اعتباری شرکت
        </p>
        <Gateways
          class="mt-4"
          @gatewaySelected="onGatewayClicked"
          :selected-gateway="selectedGateway"
        />
      </section>
      <section class="col-md-6 col-12 px-sm-3 px-0">
        <div
          class="p-3 order-type bg-color-fa fontsize-medium mb-3 mt-md-0 mt-3 text-justify"
        >
          <span
            >نوع سفارش:
            <span
              @click="(e) => selectReserved(e, false)"
              class="cursor-pointer ml-3 mr-3"
            >
              <div class="align-self-center ml-2 d-inline-block">
                <label class="address-checked-container">
                  <input
                    type="radio"
                    class="ml-1"
                    name="reserved"
                    :checked="!reserved"
                  />
                  <span class="address-checkmark"></span>
                </label>
              </div>
              <span class="mr-3"> عادی </span>
            </span>
           
          </span>
        </div>
        <div class="p-3 bg-color-fa mb-3 mt-md-0 mt-3 text-justify">
          <span class="text-color-666 fontsize-small ">این سفارش به اسم </span>
          <span class="weight-bold fontsize13 text-color-444">
            {{ selectedAddress.first_name + " " + selectedAddress.last_name }}
          </span>
          <span class="text-color-666 fontsize-small ">به آدرس </span>
          <span class="weight-bold fontsize13 text-color-444"
            >{{ selectedAddress.address }}
          </span>
          <span class="text-color-666 fontsize-small ">به شماره تماس </span>
          <span class="weight-bold fontsize13 text-color-444"
            >{{ selectedAddress.mobile }}
          </span>
          <span class="text-color-666 fontsize-small "
            >ارسال و تحویل می گردد
          </span>
        </div>
        <div class="p-3 bg-color-fa border  ">
          <div class="border-bottom">
            <div class="text-color-444 d-flex justify-content-between mb-2">
              <span class="fontsize-medium weight-bold"
                >مجموع قیمت های کالا:
              </span>
              <span>
                <span class="fontsize-medium ml-1">{{
                  (totalPrice + totalSaving) | price
                }}</span>
                <span class="fontsize-small text-color-999">تومان</span>
              </span>
            </div>
            <div class="text-color-444 d-flex justify-content-between mb-2">
              <span class="fontsize-medium weight-bold"> تخفیف: </span>
              <span>
                <span class="ml-4" v-if="totalSaving === 0">―</span>
                <template v-else>
                  <span class=" fontsize-medium ml-1 weight-bold ">{{
                    (totalSaving ) | price
                  }}</span>
                  <span class="fontsize-small ">تومان</span>
                </template>
              </span>
            </div>
            <div class="text-color-444 d-flex justify-content-between mb-2">
              <span class="fontsize-medium weight-bold"> هزینه ارسال: </span>
              <span>
                <span class="fontsize-medium mr-1" v-if="shippingPrice === 0"
                  >رایگان</span
                >
                <template v-else>
                  <span class="fontsize-medium ml-1">{{
                    shippingPrice | price
                  }}</span>
                  <span class="fontsize-small text-color-999">تومان</span>
                </template>
              </span>
            </div>
            <div
              v-if="discount && discount.discount > 0"
              class="text-color-444 d-flex justify-content-between mb-2"
            >
              <span class="fontsize-medium weight-bold text-color-discount">
                تخفیف از کوپن:
              </span>
              <span class="text-color-discount">
                <span class=" fontsize-medium ml-1 weight-bold ">{{
                  discount.discount | price
                }}</span>
                <span class="fontsize-small ">تومان</span>
              </span>
            </div>
          </div>
          <div class="text-color-444 d-flex justify-content-between mt-2">
            <span class="fontsize-medium weight-bold"> پرداخت نهایی: </span>
            <span class="text-color-theme">
              <span class=" weight-bold fontsize-large ml-1">{{
                (finalTotal ) | price
              }}</span>
              <span class="fontsize-medium">تومان</span>
            </span>
          </div>
        </div>
      </section>
    </div>
    <div
      class="d-flex justify-content-between align-items-baseline py-3 px-sm-3 px-0"
    >
 
      <button
        id="prev-btn-step-3"
        class="continue-buy-process py-2 w-25 ml-2 fontsize-medium text-center"
        @click="prevPage"
      >
        <ZArrow />
        مرحله قبل
      </button>
      <button
        class="continue-buy-process py-2  w-25 text-center fontsize-medium"
        :disabled="
          waitingForPayStatus != 'init' && waitingForPayStatus != 'error'
        "
        @click="pay"
      >
        <template v-if="waitingForPayStatus == 'init'">پرداخت</template>
        <template v-else-if="waitingForPayStatus == 'loading'">پرداخت</template>
        <template v-else-if="waitingForPayStatus == 'redirecting'"
          >درحال انتقال به درگاه</template
        >
        <template v-else-if="waitingForPayStatus == 'error'"
          >تلاش مجدد</template
        >
      </button>
    </div>
  </section>
</template>
<script>
import Gateways from "../shared/Gateways";
import ZArrow from "@/parts/Front/components/shared/ZArrow";
export default {
  name: "CartStep3",
  components: { ZArrow, Gateways },
  data() {
    return {
    };
  },
  props: {
    gateways: Array,
    selectedGateway: Object,
    selectedAddress: Object,
    discount: Number,
    shippingPrice: Number,
    total: Number,
    totalSaving: Number,
    totalPrice: Number,
    payWallet: Boolean,
    reserved: Boolean,
    waitingForPayStatus: String,
    finalTotal: Number,
    reservations: Array,
    selectedShipping: Object,
  },
  mounted() {
    // Select default
    if (this.gateways && this.gateways.length && !this.selectedGateway) {
      this.$emit("gatewaySelected", this.gateways[0]);
    }
  },
  computed: {

    user() {
      return this.homeData.user.user;
    },
    homeData() {
      return this.$store.getters["front/getHomeData"];
    },
  },
  methods: {
    nextPage() {
      this.$emit("next-page");
    },
    prevPage() {
      this.$emit("prev-page");
    },
    onGatewayClicked(gateway) {
      this.$emit("gatewaySelected", gateway);
    },
    pay() {
      this.$emit("pay");
    },
    async selectPayMethod(e, wallet) {
      this.$nextTick(() => {
        this.$refs.radioGateway.click();
        this.$emit("payMethodSelected", false);
      });
    },

    selectReserved(e, reserved) {
      this.$emit("reserveSelected", reserved);
    },
  },
};
</script>
<style scoped>
.wallet-img {
  width: 27px;
  height: 24px;
}
.order-type .address-checkmark,
.order-type .address-checkmark::after {
  border-radius: 25px;
}

#prev-btn-step-3 {
  background: var(--color-theme2);
  color: black !important;
}
</style>
